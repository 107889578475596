import React from "react"
import styled from "styled-components"
import ButtonShop from "./ButtonShop"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &.soldout {
    pointer-events: none;
    opacity: 0.2;
  }
`

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`

const ATC = styled.p`
  margin: 0;
`

const Count = styled.span`
  color: #0a0908;
  font-size: 20px;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #0a0908;
  border-right: none;
  border-left: none;
  background: #fff;
`

const CartHandler = ({ ticket, ev, selected }) => {
  
  return (
    <Outer>
      <ATC>{ticket.soldout ? "Soldout" : "Add to cart"}</ATC>
      <Wrapper className={ticket.soldout ? "soldout" : ""}>
        <ButtonShop
          type="ticket"
          action="remove"
          guid={ticket.guid}
          name={ticket.ticketTitle}
          category={ev.title}
        />
        <Count>{selected ? selected : 0}</Count>
        <ButtonShop
          type="ticket"
          action="add"
          guid={ticket.guid}
          price={ticket.price || "0.0"}
          name={ticket.ticketTitle}
          category={ev.title}
        />
      </Wrapper>
    </Outer>
  )
}

export default CartHandler
