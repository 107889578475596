import React, { useEffect, useState } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import Img from "gatsby-image"
import arrow from "../images/arrow.svg"
import Lightbox from "../components/styles/Lightbox"
import LightboxCloseBtn from "../components/styles/LightboxCloseBtn"
import CloseIcon from "../components/styles/CloseIcon"

const NewsItem = styled.div`
  width: 50%;
  &:nth-child(even) {
    button {
      border-left: 0;
    }
  }
  @media (max-width: 568px) {
    width: 100%;
    height: auto;

    margin-top: -1px;
  }
`
const Button = styled.button`
  cursor: pointer;
  color: #0a0908;
  padding: 25px;
  position: relative;
  height: 180px;
  border: 1px solid #1a1919;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0 -1px -1px 0;
  background: #fff;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    bottom: 21px;
    right: 21px;
    width: 38px;
    height: 31px;
    background: url(${arrow}) no-repeat;
    background-size: cover;
  }
  @media (max-width: 568px) {
    width: 100%;
    height: 100%;
    margin: 0;
    /* margin: 0 0 -1px; */
    /* padding: 12px 16px 30px; */
    padding: 15px 80px 15px 15px;
    border-left: 1px solid #1a1919 !important;
    &:after {
      bottom: 13px;
      right: 16px;
      width: 22px;
      height: 18px;
    }
  }
`

const Wrapper = styled.div`
  max-width: 960px;
  position: relative;
`
const Frame = styled.div`
  background: rgba(255, 255, 255, 0.9);
  max-height: 96vh;
  overflow: auto;
`

const Image = styled.div`
  img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  padding: 24px 28px;
  border: 1px solid #1a1919;
  border-top: 0;
  font-size: 18px;
  li {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
  ol,
  ul,
  p {
    margin-bottom: 10px;
  }
  @media (max-width: 568px) {
    padding: 17px;
  }
`

const Title = styled.h3`
  color: #0a0908;
  font-size: 32px;
  margin: 0 0 20px;
  @media (max-width: 568px) {
    font-size: 28px;
  }
`

const Date = styled.span`
  font-size: 15px;
`

function NewsItemComponent({ data }) {
  const [opened, setOpen] = useState(null)

  useEffect(() => {
    if (opened != null) {
      if (opened) {
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = "fixed"
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ""
        document.body.style.top = ""
        window.scrollTo(0, parseInt(scrollY || "0") * -1)
      }
    }
  }, [opened])
  return (
    <NewsItem>
      <Button
        onClick={() => {
          setOpen(!opened)
        }}
        onKeyDown={() => {
          setOpen(!opened)
        }}
      >
        <Date>{data.createdAt}</Date>
        <span>{data.title}</span>
      </Button>
      {opened && (
        <Lightbox>
          <Wrapper>
            <Frame>
              <Image>
                <Img fluid={data.image.fluid} alt={data.image.title} />
              </Image>
              <Content>
                <Date>Published at {data.createdAt}</Date>
                <Title>{data.title}</Title>
                {documentToReactComponents(JSON.parse(data.body.raw))}
              </Content>
            </Frame>
            <LightboxCloseBtn
              onClick={() => {
                setOpen(false)
              }}
              onKeyDown={() => {
                setOpen(false)
              }}
            >
              <CloseIcon />
            </LightboxCloseBtn>
          </Wrapper>
        </Lightbox>
      )}
    </NewsItem>
  )
}
export default NewsItemComponent
