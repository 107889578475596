import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import NewsItem from "../components/NewsItem"

const News = styled.div`
  max-width: 964px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
`
const Btn = styled.button`
  display: inline-block;
  color: #0a0908;
  border: 1px solid #1a1919;
  font-size: 24px;
  line-height: 60px;
  width: 240px;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff;
  }
  @media (max-width: 568px) {
    font-size: 16px;
    display: block;
    width: 100%;
    line-height: 40px;
    margin-top: -1px;
  }
`
const Title = styled.h2`
  font-weight: bold;
  font-size: 64px;
  color: #0a0908;
  margin: 0 0 60px;
  text-align: left;
  @media (max-width: 568px) {
    font-size: 24px;
    margin: 0 0 16px;
  }
`
const Wrapper = styled.div`
  font-size: 24px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  text-align: left;
  @media (max-width: 568px) {
    flex-direction: column;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
`

function NewsComponent() {
  const [qty, setQty] = useState(2)
  const [multiplier, setMultiplier] = useState(2)

  const data = useStaticQuery(graphql`
    {
      allContentfulBlogpost(sort: { fields: createdAt, order: DESC }) {
        nodes {
          title
          slug
          body {
            raw
          }
          createdAt(formatString: "DD-MM-YYYY")
          publishedAt(formatString: "DD-MM-YYYY")
          updatedAt(formatString: "DD-MM-YYYY")
          image {
            title
            fluid(maxWidth: 960) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const blogsData = data.allContentfulBlogpost.nodes

  return (
    <News>
      <Title>News</Title>
      <Wrapper>
        {blogsData.map((node, i) => {
          if (i < qty * multiplier) {
            return <NewsItem key={i} data={node} />
          } else {
            return ""
          }
        })}
      </Wrapper>
      {blogsData.length > qty * multiplier && (
        <Btn
          onClick={() => {
            setMultiplier(prevMultiplier => prevMultiplier + 1)
            return false
          }}
        >
          Show more
        </Btn>
      )}
    </News>
  )
}

export default NewsComponent
