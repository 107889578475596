import styled from 'styled-components'

const CloseIcon = styled.div`
    width: 26px;
  height: 26px;
  transform: rotate(-45deg);
  transform-origin: center;

  top: -10px;
left: -8px;

position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    width: 26px;
    height: 1px;
    background: #fff;
  }
  &:before {
    content: "";
    position: absolute;
    left: 13px;
    top: 0;
    width: 1px;
    height: 26px;
    background: #fff;
  }
`

export default CloseIcon