import styled from "styled-components"

const Lightbox = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${props =>
    props.bgcolor ? props.bgcolor : "rgba(26, 25, 25, 0.4)"};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 568px) {
    padding: 64px 0 0;
  }
`
export default Lightbox
