import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import AnimateHeight from "react-animate-height"

import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Triangle from "../components/styles/Triangle"
import News from "../components/News"
import ComingSoon from "../components/ComingSoon"

import dateFormat from "dateformat"
import arrow from "../images/arrow.svg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Slider from "react-slick"

import ButtonShop from "./../components/ButtonShop"
import CartHandler from "../components/CartHandler"

const Title = styled.h1`
  color: #1a1919;
  font-size: 80px;
  /* margin: 0 0 40px 120px; */
  display: flex;
  flex-direction: column;
  max-width: 964px;
  margin: 0 auto 40px auto;
  line-height: 1.05;
  strong {
    cursor: pointer;
  }
  .not-active {
    color: #d8d8d8;
    position: relative;
    align-self: flex-start;
    transition: color 0.15s ease-out;
    &:hover {
      color: #1a1919;
    }
  }
  .active {
    pointer-events: none;
  }
  @media (max-width: 568px) {
    font-size: 36px;
    /* margin: 0 0 24px 65px; */
    span {
      &:after {
        height: 4px;
        left: -5px;
        width: calc(100% + 10px);
      }
    }
  }
`

const MainImage = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;
  display: block;
  width: 100%;
  height: 540px;
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }
  @media (max-width: 800px) {
    height: auto;
  }
`

const EventDetails = styled.div`
  background: rgba(255, 255, 255, 0.7);
  /* border: 1px solid #1a1919; */
  /* border-top: none; */
  padding: 25px;
  position: relative;
  color: #000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    padding: 48px 18px 16px;
    /* border-left: 0;
    border-right: 0;
    border-top: 1px solid #1a1919; */
  }
`
const Paragraph = styled.div`
  margin: 0;
`
const DateEl = styled.em`
  display: block;
  font-style: normal;
  margin-bottom: 20px;
  font-size: 14px;
  @media (max-width: 568px) {
    line-height: 20px;
    margin-bottom: 12px;
  }
`

const Info = styled.div`
  margin-bottom: 24px;
  /* width: 44%; */
  ${props =>
    props.activeTickets
      ? `
    width: 44%;
  `
      : `
    width: 100%;
  `}
  @media (max-width: 568px) {
    order: 0;
    width: 100%;
  }
`

const Tickets = styled.div`
  width: 49%;
  margin-bottom: 24px;
  @media (max-width: 568px) {
    order: 3;
    width: 100%;
    button {
      width: 100%;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
  }
`

const Lineup = styled.div`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1.2;
  margin: 0 0 30px;
  width: 100%;
  h3 {
    font-size: 32px;
    margin: 0 0 8px;
    color: #000;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 16px;
    columns: 2;
  }
  li {
    margin: 0;
  }
  a {
    color: #000;
    display: inline-block;
    padding-right: 24px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 7px);
      width: 18px;
      height: 14px;
      background: url(${arrow}) no-repeat;
      background-size: cover;
      transform: rotate(-45deg);
    }
  }
  @media (max-width: 568px) {
    order: 2;
    ul {
      columns: 1;
      padding: 0;
    }
  }
`
const EventFooter = styled.footer`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-end;
  dl {
    width: 48%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  dd {
    margin: 0;
  }
  dt {
    margin-right: 16px;
    font-weight: 300;
    line-height: 1;
  }
  a {
    color: #0a0908;
    font-size: 20px;
    line-height: 38px;
    padding: 0 40px 0 16px;
    border: 1px solid #0a0908;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: calc(50% - 9px);
      width: 22px;
      height: 18px;
      background: url(${arrow}) no-repeat;
      background-size: cover;
      transform: rotate(-45deg);
    }
  }
  @media (max-width: 568px) {
    order: 1;
    margin-bottom: 24px;
    dl {
      justify-content: center;
      width: 58%;
      &:first-child {
        width: 40%;
      }
      dd {
        width: 100%;
      }
      a {
        width: 100%;
      }
    }
    dt {
      display: none;
    }
  }
`

const Event = styled.div`
  padding-bottom: 60px;
  max-width: 960px;
  margin: 0 auto;
  @media (max-width: 568px) {
    margin: 0 -10px;
    padding-bottom: 16px;
  }
`

// const CarouselButton = styled.button`
//     position: relative;
//     width: 100%;
//     height: 100%;
// `

const EventTicket = styled.div`
  margin-bottom: 24px;
`

// const InfoBtn = styled.span`
//   font-size: 16px;
//   color: #fff;
//   border: 1px solid #fff;
//   border-radius: 50%;
//   width: 24px;
//   line-height: 22px;
//   text-align: center;
//   cursor: pointer;
// `

const EventTicketTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a1919;
  margin-bottom: 16px;
  color: #fff;
  font-size: 24px;
  padding: 5px 8px 5px 18px;
  img {
    margin: 0;
    width: 24px;
    height: 24px;
  }
  @media (max-width: 568px) {
    font-size: 16px;
  }
`

const Close = styled.button`
  position: absolute;
  right: 22px;
  top: 22px;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  img {
    margin: 0;
    transform: rotate(-90deg);
  }
  @media (max-width: 568px) {
    right: 16px;
    top: 18px;
    img {
      width: 20px;
      height: 18px;
    }
  }
`

const Container = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`

const EventHeader = styled.div`
  position: relative;
`

const EventImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56.2%;
`

const EventImage = styled.img`
  /* max-width: 50em; */
  margin: 0 auto;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const ButtonShow = styled.button`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  right: 15px;
  bottom: 15px;
  padding: 10px 35px;
  border: 1px solid black;
  border-radius: 0;
  cursor: pointer;
  color: #000;

  img {
    display: none;
    pointer-events: none;
  }

  @media (max-width: 568px) {
    position: relative;
    right: initial;
    bottom: initial;
    width: 100%;
    padding: 10px 15px;
    outline: none;

    img {
      display: block;
      flex-shrink: 0;
    }
  }
`

const ButtonNav = styled.button`
  order: 1;
  border-radius: 0;
  background: transparent;
  border: 0;
  width: 50px;
  height: 50px;
  padding: 10px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    img {
      opacity: 0.5;
    }
  }

  &:first-of-type {
    margin-right: 10px;
  }

  &:last-of-type {
    margin-right: 18vw;
  }

  @media (max-width: 568px) {
    width: 35px;
    height: 35px;

    &:first-of-type {
      margin-right: 5px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const Carousel = styled(Slider)`
  margin-top: -100px;

  .slick-slide {
    outline: none;
  }

  .slick-list {
    margin-top: 100px;
    flex-grow: 1;
    order: 2;
    margin-bottom: 50px;
  }

  @media (max-width: 568px) {
    margin-top: -85px;

    .slick-list {
      margin-top: 50px;
    }
  }
`

const EventInner = styled.div`
  padding: 0 10px;
  transition: opacity 0.25s ease;
  outline: none;

  &:hover {
    opacity: 0.9;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  @media (min-width: 1024px) {
    padding: 0 20px;
  }

  @media (min-width: 1440px) {
    padding: 0 35px;
  }
`

const Arrow = props => {
  return (
    <ButtonNav onClick={props.onClick}>
      <img
        src={arrow}
        alt="Arrow"
        style={{
          margin: 0,
          transformOrigin: "center",
          transform:
            props.direction == "next" ? "rotate(0deg)" : "rotate(180deg)",
        }}
      />
    </ButtonNav>
  )
}

function IndexPage({ data }) {
  const [allEvents, setAllEvents] = useState(data.allContentfulEvent.nodes)
  const [selectedTickets, setSelectedTickets ] = useState({});
  const [activeIndex, setActiveIndex] = useState(0)
  const [openEventInfo, setEventInfo] = useState(null)

  const trackViewContent = title => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "ViewContent", {
          content_name: title,
          content_category: "Event",
        })
      }
    }
  }

  const carouselRef = useRef(null)

  const currentDate = Date.now()
  const pastEvents = []
  const futureEvents = []

  allEvents.map(ev =>
    currentDate > Date.parse(ev.date)
      ? pastEvents.push(ev)
      : futureEvents.push(ev)
  )

  useEffect(() => {
    setTimeout(() => {
      carouselRef.current.slickGoTo(allEvents.length - futureEvents.length)
    }, 1000)
    setActiveIndex(allEvents.length - futureEvents.length)
    // console.log(carouselRef.current)
  }, [])

  useEffect(() => {
    const handleCartTicketsUpdated = cartTickets => {
      console.log("CartHandle", cartTickets);
      const updatedValue = {}
      Object.entries(cartTickets).forEach(([key, value]) => {
        updatedValue[key] = value;
      });      

      setSelectedTickets({
        ...selectedTickets,
        ...updatedValue
      })
    }

    window.OtShopWidget.cartTicketsUpdated(handleCartTicketsUpdated)
  }, [])


  var settings = {
    // className: "center",
    centerMode: true,
    centerPadding: "25px",
    slidesToShow: 1,
    speed: 500,
    infinite: false,
    initialSlide: allEvents.length - futureEvents.length,
    // initialSlide: allEvents.length - 1,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
    afterChange: current => {
      if (current != activeIndex) {
        setEventInfo(null)
      }
    },
    beforeChange: (current, next) => {
      setActiveIndex(next)
    },
    responsive: [
      {
        breakpoint: 2600,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1920,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "25px",
        },
      },
      {
        breakpoint: 325,
        settings: {
          //   slidesToShow: 1.25,
          //   slidesToScroll: 1,
          //   centerMode: true,
          centerPadding: "25px",
        },
      },
    ],
  }

  return (
    <Layout noContainer>
      <Seo
        title="Komm schon Alter | Melodic house and techno events"
        description="Festival & club nights | No kids on the dance floor, only 24+ | tickets, line-up and more information"
        keywords="Das Festival, club night, Amsterdam, house, techno, mature, tickets, ADE, line-up, blog"
      />
      <Container>
        <Title>
          <strong>Events</strong>
        </Title>
      </Container>
      <Carousel
        ref={carouselRef}
        {...settings}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        {allEvents.map((ev, id) => {
          return (
            <div key={id} style={{ outline: "none" }}>
              <EventInner>
                <EventHeader>
                  <EventImageWrapper>
                    <EventImage src={ev.image.file.url} alt={ev.image.alt} />
                  </EventImageWrapper>
                  {activeIndex == id && (
                    <ButtonShow
                      data-id={id}
                      onClick={e => {
                        e.preventDefault()

                        if (openEventInfo == id) {
                          setEventInfo(null)
                        } else {
                          setEventInfo(e.target.dataset.id)
                        }
                        trackViewContent(ev.title)
                      }}
                    >
                      {openEventInfo == id ? "Close" : "Info & Tickets"}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{
                          width: "20px",
                          margin: 0,
                          transformOrigin: "center",
                          pointerEvents: "none",
                          transform:
                            openEventInfo == id
                              ? "rotate(-90deg)"
                              : "rotate(90deg)",
                        }}
                      />
                    </ButtonShow>
                  )}
                </EventHeader>
                <AnimateHeight height={openEventInfo == id ? "auto" : 0}>
                  <EventDetails>
                    <Info activeTickets={ev.tickets}>
                      <DateEl>
                        {dateFormat(ev.date, "mmmm dS, yyyy")} - {ev.time}
                      </DateEl>
                      <Paragraph>
                        {documentToReactComponents(JSON.parse(ev.body.raw))}
                      </Paragraph>
                    </Info>
                    {ev.tickets && ev.tickets.length > 0 && (
                      <Tickets>
                        {ev.tickets.map((ticket, index) => (
                          <EventTicket key={index}>
                            <EventTicketTitle>
                              <span>{ticket.ticketTitle}</span>
                              {ticket.price && <span>€{ticket.price}</span>}
                            </EventTicketTitle>
                            {ticket.guid ? (
                                <CartHandler ticket={ticket} ev={ev} selected={selectedTickets[ticket.guid]? selectedTickets[ticket.guid]?.selected : 0} />
                            ) : (
                              <p>Not yet on sale </p>
                            )}
                          </EventTicket>
                        ))}
                      </Tickets>
                    )}
                    <Lineup>
                      <h3>Full Line-Up</h3>
                      <ul>
                        {ev.artists.map((artist, index) => (
                          <li key={index}>
                            <a href={artist.link}>{artist.title}</a>
                          </li>
                        ))}
                      </ul>
                    </Lineup>
                    <EventFooter>
                      <dl>
                        <dt>Find answers</dt>
                        <dd>
                          <Link to="/faq">FAQ</Link>
                        </dd>
                      </dl>
                      {ev.facebookLink && (
                        <dl>
                          <dt>Join our community</dt>
                          <dd>
                            <a
                              href={ev.facebookLink}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Facebook
                            </a>
                          </dd>
                        </dl>
                      )}
                    </EventFooter>
                  </EventDetails>
                </AnimateHeight>
              </EventInner>
            </div>
          )
        })}
      </Carousel>
      <Container>
        <News />
        <Triangle />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulEvent(sort: { fields: date, order: ASC }) {
      nodes {
        title
        id
        facebookLink
        image {
          file {
            url
          }
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        date
        time
        body {
          raw
        }
        tickets {
          id
          guid
          price
          soldout
          ticketTitle
        }
        artists {
          id
          link
          title
        }
      }
    }
  }
`
export default IndexPage
