import React from "react"
import styled from "styled-components"

const Button = styled.button`
  color: #0a0908;
  font-size: 20px;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid #0a0908 !important;
  cursor: pointer;
  transition: all 0.15s ease-out;
  &:hover {
    background: #0a0908;
    color: #fff;
  }
  &:disabled {
    color: #1a1919;
    border-color: #1a1919;
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }
`

const ButtonShop = ({
  type,
  action,
  price,
  guid,
  soldout = false,
  name,
  category,
  className,
}) => {
  const clickHandler = action => {
    if (typeof window !== "undefined") {
      action == "add"
        ? window.OtShopWidget.addTicket(guid)
        : window.OtShopWidget.removeTicket(guid)

      if (window.fbq != null && action == "add") {
        window.fbq("track", "AddToCart", {
          content_name: name,
          content_category: category || "",
          content_ids: [guid],
          content_type: type,
          value: price,
          currency: "EUR",
        })
      }
    }
  }

  const classes = "ticket_btn btn openticket-action"

  return (
    <Button
      className={className ? `${className} ${classes}` : classes}
      data-type={type}
      data-guid={guid}
      data-action={action}
      disabled={soldout}
      type="button"
      onClick={e => {
        e.preventDefault()
        clickHandler(action)
      }}
    >
      {action == "add" ? "+" : "-"}
    </Button>
  )
}

export default ButtonShop
