import styled from "styled-components"

const LightboxCloseBtn = styled.button`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  width: 26px;
  height: 26px;
  right: 0;
  top: 0;
  border: 0;
  padding: 30px;
  cursor: pointer;
  @media (max-width: 1048px) {
    padding: 25px;
  }
`

export default LightboxCloseBtn
